<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/软件下载.jpg" width="100%" height="100%" lazy>
    <div class="font_center">下载Eye4移动客户端</div>
    <div class="flex">
      <div class="flex_l">
        <a href="https://play.google.com/store/apps/details?id=vstc.vscam.client&amp;hl=en" target="_blank">
          <img src="../../../../public/images/官网底部文字对应的二级页面/软件下载/Google.png">
        </a>
      </div>
      <div class="flex_r"><img src="../../../../public/images/底部导航/智驹app下载地址.jpg" width="220px" height="200px" lazy></div>
    </div>
    <div class="font_center">其他软件</div>
    <div class="qita">
      <div class="qita_box">
        <img src="../../../../public/images/官网底部文字对应的二级页面/软件下载/windows版.png">
        <div class="qita_box_tite">windows版</div>
        <div class="qita_box_tites">在Windows电脑上观看实时画面，抓拍、录像实时图像， 提醒，设置等功能。</div>
        <a href="https://doraemon.camera666.com/supercam-vstarcam"><img class="a_top" src="../../../../public/images/官网底部文字对应的二级页面/软件下载/下载.png" width="60px" height="44px" @click=""></a>
      </div>
      <div class="qita_box">
        <img src="../../../../public/images/官网底部文字对应的二级页面/软件下载/查找器.png">
        <div class="qita_box_tite">查找器</div>
        <div class="qita_box_tites">用于查找局域网内的摄像机，并设置摄像机IP地址等参数。</div>
        <a href="https://doraemon.camera666.com/finder-vstarcam"><img class="a_tops" src="../../../../public/images/官网底部文字对应的二级页面/软件下载/下载.png" width="60px" height="44px"></a>
      </div>
      <div class="qita_box">
        <img src="../../../../public/images/官网底部文字对应的二级页面/软件下载/存储卡录像回放工具.png">
        <div class="qita_box_tite">存储卡录像回放工具</div>
        <div class="qita_box_tites">用于播放TF卡中的录像文件。</div>
        <a href="http://doraemon.camera666.com/videoplayer.zip"><img class="a_topss" src="../../../../public/images/官网底部文字对应的二级页面/软件下载/下载.png" width="60px" height="44px"></a>
      </div>
      <div class="qita_box">
        <img src="../../../../public/images/官网底部文字对应的二级页面/软件下载/IE插件.png">
        <div class="qita_box_tite">IE插件</div>
        <div class="qita_box_tites">初次在浏览器中观看摄像机画面需要安装IE插件。</div>
        <a href="http://doraemon.camera666.com/ax-ipcam"><img class="a_tops" src="../../../../public/images/官网底部文字对应的二级页面/软件下载/下载.png" width="60px" height="44px"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "软件下载"
}
</script>

<style scoped>
.qita {
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
}

.qita_box {
  width: 14%;
  text-align: center;
  height: 360px;
  border: 1px solid #ECECEC;
  border-radius: 15px;
}
.qita_box_tite{
  font-size:21px;
}
.qita_box_tites{
  font-size:14px;
  color:#999999;
  width: 90%;
  height:75px;
  padding-top: 10px;
  margin: auto;
}
.font_center {
  margin-top: 60px;
  font-size: 24px;
  text-align: center;
}
.flex{
  margin-top: 60px;
  justify-content:center;
  display:flex;
}
.flex_l{
  padding-right: 50px;
  line-height:230px;
}
.a_top{
}
.a_tops{
  /*margin-top: 40px;*/
}
.a_topss{
  /*margin-top: 58px;*/
}

</style>